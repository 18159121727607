import React from "react"
import { graphql, Link, useStaticQuery, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Small16by10 from "../Images/Small16by10"
import Truncate from "../Truncate"

const ArticlesPreview = () => {
  const data = useStaticQuery(graphql`
    {
      firstArticle: allNodeArticle(
        limit: 1
        filter: { status: { eq: true } }
        sort: { fields: created, order: DESC }
      ) {
        nodes {
          title
          body {
            summary
            value
          }
          path {
            alias
          }
          relationships {
            banner: field_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 590
                    height: 430
                    quality: 75
                    transformOptions: { cropFocus: CENTER }
                    layout: CONSTRAINED
                  )
                }
              }
            }
            image: field_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 334
                    height: 220
                    quality: 75
                    transformOptions: { cropFocus: CENTER }
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
          internal {
            type
          }
        }
      }
      articles: allNodeArticle(
        limit: 2
        skip: 1
        filter: { status: { eq: true } }
        sort: { fields: created, order: DESC }
      ) {
        nodes {
          title
          path {
            alias
          }
          relationships {
            image: field_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 334
                    height: 220
                    quality: 75
                    transformOptions: { cropFocus: CENTER }
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="relative">
      <div className="mb-8">
        <h2 className="title h2">
          <span>L’actualité de la MDPH</span>
        </h2>
      </div>

      <div className="columns grid grid-cols-1 xl:grid-cols-2 gap-8 mb-8 xl:mb-16">
        {data.firstArticle.nodes.map((node, index) => (
          <div key={index} className="xl:flex items-end">
            <div className="column flex-1">
              <article
                className="article-list-item relative h-full cursor-pointer"
                onClick={() => navigate(node.path.alias)}
              >
                <div className="list-item-content absolute inset-x-0 bottom-0 xl:transform xl:translate-y-1/2 p-4 md:p-8 ml-16 xl:ml-0 xl:mr-16 bg-white z-10">
                  <div className="title h3 title-underlined mb:0 xl:mb-4">
                    <Link to={node.path.alias}>{node.title}</Link>
                  </div>
                  {node.body && (
                    <Truncate
                      className="content hidden xl:block"
                      limit={120}
                      html={
                        node.body.summary.length > 0
                          ? node.body.summary
                          : node.body.value
                      }
                    />
                  )}
                </div>
                <div className="list-item-image xl:pt-8 xl:pl-16 pb-16 xl:pb-0 z-0">
                  {node.relationships.image ? (
                    <>
                      {node.relationships.banner && (
                        <div className="hidden xl:block">
                          <GatsbyImage
                            image={
                              node.relationships.banner.localFile.childImageSharp.gatsbyImageData
                            }
                            alt={node.title}
                          />
                        </div>
                      )}
                      <div className={`${
                        node.relationships.banner ? `block xl:hidden` : ``
                      }`}>
                        <GatsbyImage
                          image={
                            node.relationships.image.localFile.childImageSharp.gatsbyImageData
                          }
                          alt={node.title}
                        />
                      </div>
                    </>
                  ) : (
                    <Small16by10 alt={node.title} />
                  )}
                </div>
              </article>
            </div>
          </div>
        ))}

        <div>
          <div className="grid grid-cols-1 gap-8">
            {data.articles.nodes.map((node, index) => (
              <div key={index + 1} className={`column`}>
                <article
                  className="article-list-item relative h-full cursor-pointer"
                  onClick={() => navigate(node.path.alias)}
                >
                  <div className="list-item-content absolute inset-x-0 bottom-0 p-4 md:p-8 ml-16 bg-white z-10">
                    <div className="mb-0 title h3 title-underlined">
                      <Link to={node.path.alias} className="text-primary">
                        {node.title}
                      </Link>
                    </div>
                  </div>
                  <div className="list-item-image relative xl:w-1/2 pb-16 xl:pb-0 z-0">
                    {node.relationships.image ? (
                      <>
                        {node.relationships.banner && (
                          <GatsbyImage
                            image={
                              node.relationships.banner.localFile
                                .childImageSharp.gatsbyImageData
                            }
                            alt={node.title}
                            className="hidden md:block"
                          />
                        )}
                        <GatsbyImage
                          image={
                            node.relationships.image.localFile.childImageSharp
                              .gatsbyImageData
                          }
                          alt={node.title}
                          className={`${
                            node.relationships.banner ? `block md:hidden` : ``
                          }`}
                        />
                      </>
                    ) : (
                      <Small16by10 alt={node.title} />
                    )}
                  </div>
                </article>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <div className="more-link w-full xl:w-1/2">
          <Link to="/actualites/" className="button button-primary">
            <span className="flex-1">Voir toutes les actualités </span>
            <span className="icon ml-2" aria-hidden="true">
              <span className="fas fa-arrow-right" />
            </span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ArticlesPreview
