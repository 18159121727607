import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import A4 from "../Images/A4"

const PublicationsPreview = () => {
  const data = useStaticQuery(graphql`
    {
      publications: allNodePublication(
        sort: { fields: created, order: DESC }
        filter: { status: { eq: true } }
        limit: 3
      ) {
        nodes {
          title
          created
          relationships {
            image: field_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 288
                    height: 412
                    quality: 75
                    transformOptions: { cropFocus: CENTER }
                    layout: CONSTRAINED
                  )
                }
              }
            }
            file: field_fichier {
              localFile {
                publicURL
                prettySize
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="relative">
      <div
        className="hidden lg:block absolute right-2/3 w-screen top-0 h-96 -mt-48 bg-primary-lighter"
        role="presentation"
      >
        {" "}
      </div>
      <div className="flex flex-col lg:flex-row -mx-4">
        <div className="order-last lg:w-3/8 px-4">
          <div className="lg:px-12">
            <h2 className="title h2">
              <span>Le kiosque de la MDPH</span>
            </h2>

            <div className="mb-12 lg:mb-20">
              <p>
                Accédez à l’ensemble des publications de la MDPH. Pour rester
                bien informés de vos droits et prestations disponibles.
              </p>
            </div>

            <div className="more-link">
              <Link to="/publications/" className="button button-primary">
                <span className="flex-1">Accéder au kiosque</span>
                <span className="icon ml-4" aria-hidden="true">
                  <span className="fas fa-arrow-right" />
                </span>
              </Link>
            </div>
          </div>
        </div>
        <div className="order-first lg:w-5/8 px-4 mb-12 lg:mb-0">
          <div className="columns grid grid-cols-3 gap-8">
            {data.publications.nodes.map((node, index) => (
              <div key={index} className="column publication">
                <a
                  href={node.relationships.file.localFile.publicURL}
                  title={`Télécharger "${node.title}" (${node.relationships.file.localFile.prettySize})`}
                  target="_blank"
                  rel="noreferrer"
                  className="group relative block bg-blue-night shadow"
                  download
                >
                  {node.relationships.image ? (
                    <GatsbyImage
                      image={
                        node.relationships.image.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      alt={node.title}
                      className="group-hover:opacity-30 transition-all"
                    />
                  ) : (
                    <A4
                      alt={node.title}
                      className="group-hover:opacity-30 transition-all"
                    />
                  )}
                  <div
                    className="icon absolute top-1/2 left-1/2 transform scale-0 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center h-12 w-12 bg-primary-lighter rounded-full text-primary group-hover:scale-100 transition-all"
                    aria-hidden="true"
                  >
                    <span className="fas fa-file-download"></span>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublicationsPreview
