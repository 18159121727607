import React from "react"
import Layout from "../components/Layout"
import Metas from "../components/Metas"
import Block from "../components/Block"
import ArticlesPreview from "../components/Blocks/ArticlesPreview"
import HelpsMenu from "../components/Menus/HelpsMenu"
import PublicationsPreview from "../components/Blocks/PublicationsPreview"
import ServicesMenu from "../components/Menus/ServicesMenu"
import ArrowSvg from "../images/arrow.svg"

const IndexPage = () => (
  <Layout isHome>
    <Metas
      title="Accueil"
      description="Bienvenue sur le site de la Maison Départementale des Personnes Handicapées des Ardennes"
    />
    <Block id="helps-menu--block" className="lg:-mt-32" container>
      <section className="relative py-12 lg:py-16 lg:px-8">
        <div
          className="hidden lg:block absolute left-3/4 w-screen top-0 h-72 mt-32 bg-primary-lighter"
          aria-hidden="true"
        >
          &nbsp;
        </div>
        <div
          className="absolute inset-y-0 left-1/2 lg:left-auto lg:right-0 w-screen bg-primary transform -translate-x-1/2 lg:translate-x-0"
          aria-hidden="true"
        >
          &nbsp;
        </div>
        <div className="relative flex flex-col lg:flex-row lg:items-end text-white">
          <div className="flex-initial lg:w-1/3 xl:w-1/4 lg:px-8">
            <h2 className="title h4">
              <span>Mes aides</span>
            </h2>
            <p className="text-xl font-important font-bold">
              Retrouvez les aides adaptées
              <span className="block">à votre profil</span>
            </p>
          </div>
          <div className="flex-1 lg:w-2/3 xl:w-3/4 lg:px-8">
            <HelpsMenu />
          </div>
        </div>
      </section>
    </Block>
    <Block id="articles-preview--block" container section>
      <ArticlesPreview />
    </Block>
    <Block id="services-menu--block" className="relative z-10" container>
      <section className="section relative lg:px-8">
        <div className="absolute inset-y-0 left-1/2 lg:left-0 w-screen bg-primary transform -translate-x-1/2 lg:translate-x-0">
          &nbsp;
        </div>
        <div className="relative flex flex-col lg:flex-row text-white lg:items-start">
          <div className="flex-initial lg:w-1/4 lg:px-8">
            <div className="hidden lg:block relative" aria-hidden="true">
              <div className="absolute top-0 left-0 -mt-24">
                <ArrowSvg />
              </div>
            </div>
            <h2 className="title h2">
              <span>
                La MDPH
                <br /> à vos côtés
              </span>
            </h2>
          </div>
          <div className="flex-1 lg:w-3/4 lg:px-8">
            <ServicesMenu />
          </div>
        </div>
      </section>
    </Block>
    <Block id="publications-preview--block" container section>
      <PublicationsPreview />
    </Block>
  </Layout>
)

export default IndexPage
