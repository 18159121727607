import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import MenuLink from "../MenuLink"

const HelpsMenu = () => {
  const data = useStaticQuery(graphql`
    {
      helpsMenu: allMenuLinkContentMenuLinkContent(
        filter: {
          enabled: { eq: true }
          menu_name: { eq: "main" }
          drupal_parent_menu_item: {
            eq: "menu_link_content:eb37fef6-4857-40c6-85c7-6f96a34edc5a"
          }
        }
        sort: { fields: weight, order: ASC }
      ) {
        nodes {
          drupal_id
          title
          link {
            uri
          }
        }
      }
    }
  `)

  return (
    <>
      <ul
        id="helps--menu"
        className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-0 lg:gap-8"
      >
        {data.helpsMenu.nodes.map((menu, index) => (
          <li className="menu-item xl:text-center" key={index}>
            <MenuLink
              internalId={menu.link.uri}
              className="menu-link flex lg:inline-flex px-2 lg:px-12 py-4 lg:py-6 lg:rounded-full border-b lg:border-b-0 border-white border-opacity-50 font-medium lg:bg-white text-white lg:text-blue-night lg:hover:bg-primary-lighter lg:hover:text-blue-night"
            >
              <div className="flex-1 lg:flex-initial">{menu.title}</div>
              <div className="icon ml-4 text-primary-light" aria-hidden="true">
                <i className="fas fa-arrow-right"></i>
              </div>
            </MenuLink>
          </li>
        ))}
      </ul>
    </>
  )
}

export default HelpsMenu
