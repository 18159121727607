import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { iconInvisibleText } from "../../Utils/functions"
import MenuLink from "../MenuLink"

const ServicesMenu = () => {
  const data = useStaticQuery(graphql`
    {
      servicesMenu: allMenuLinkContentMenuLinkContent(
        filter: { enabled: { eq: true }, menu_name: { eq: "services" } }
        sort: { fields: weight, order: ASC }
      ) {
        nodes {
          drupal_parent_menu_item
          drupal_id
          title
          link {
            uri
            options {
              attributes {
                class
                target
                data_has_icon
                data_icon
                data_text_invisible
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <div id="services--menu">
        <ul className="flex flex-col md:flex-row flex-wrap -mx-4">
          {data.servicesMenu.nodes.map((menu, index) => (
            <li className="menu-item px-4 mb-8" key={index}>
              <MenuLink
                internalId={menu.link.uri}
                className="menu-link flex sm:inline-flex px-8 sm:px-12 py-6 rounded-full bg-white text-blue-night font-medium hover:bg-primary-lighter hover:text-blue-night"
              >
                {menu.link.options.attributes &&
                menu.link.options.attributes.data_has_icon ? (
                  <>
                    <div
                      className="icon mr-4 text-primary-light"
                      aria-hidden="true"
                    >
                      <i className={menu.link.options.attributes.data_icon}></i>
                    </div>
                    <div>
                      {menu.link.options.attributes.data_text_invisible
                        ? iconInvisibleText(menu.title)
                        : menu.title}
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="icon mr-4 text-primary-light"
                      aria-hidden="true"
                    >
                      <i className="fas fa-link"></i>
                    </div>
                    <div>{menu.title}</div>
                  </>
                )}
              </MenuLink>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default ServicesMenu
